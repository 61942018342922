import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';
import './styles/common.css';
import './styles/overwriteStyles';

ReactDOM.render(
    <App />,
    document.getElementById('app')
);

module.hot.accept();