import React from 'react';
import PropTypes from 'prop-types';
import { Icon as AntdIcon } from 'antd';
import { spacing, colors } from '../../../../styles';

export const ICONS = {
    appstore: 'appstore',
    arrowDown: 'arrowDown',
    arrowUp: 'arrowUp',
    barChart: 'bar-chart',
    check: 'check',
    close: 'close',
    contacts: 'contacts',
    dislike: 'dislike',
    dollar: 'dollar',
    download: 'download',
    infoCircle: 'info-circle',
    lock: 'lock',
    logout: 'logout',
    menu: 'menu',
    more: 'more',
    plus: 'plus',
    pushPin: 'pushpin',
    plusCircle: 'plus-circle',
    remove: 'delete',
    save: 'save',
    star: 'star',
    sync: 'sync',
    unlock: 'unlock',
    user: 'user',
    userAdd: 'user-add'
};


class Icon extends React.PureComponent {
    static propTypes = {
        type: PropTypes.string.isRequired,
        size: PropTypes.number,
        color: PropTypes.string
    };

    static defaultProps = {
        size: spacing.s3,
        color: colors.primary
    };

    get iconStyle() {
        return {
            fontSize: this.props.size,
            color: this.props.color
        };
    }

    render() {
        return (
            <AntdIcon
                {...this.props}
                style={this.iconStyle}
            />
        );
    }
}

export default Icon;