import _ from 'lodash';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { components as Core, useTranslation } from '../../../core';
import * as selectors from '../../selectors';
import { removeCustomerData } from "../../actions";
import { StyleSheet, css, colors, fonts, spacing } from '../../../../styles';

const FIELDS = {
    encryptedCustomersCount: 'encryptedCustomersCount',
    encryptedOrderCount: 'encryptedOrderCount'
}

const MODAL_WIDTH = 400;

export const RemoveCustomerDataModal = ({ onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const removedData = useSelector(selectors.getRemovedData);
    const isRemovingData = useSelector(selectors.isRemovingData);
    const [searchTerm, setSearchTerm] = useState('');

    const fields = useCallback(() => [{
        fieldName: FIELDS.encryptedCustomersCount,
        label: t('encrypt.encryptedCustomersCount')
    }, {
        fieldName: FIELDS.encryptedOrderCount,
        label: t('encrypt.encryptedOrderCount')
    }], [removedData]);

    const searchAndRemove = () => dispatch(removeCustomerData(searchTerm));

    const showField = () => removedData && (removedData.encryptedCustomersCount != null || removedData.encryptedOrderCount != null)

    return (
        <Core.Modal
            isVisible={true}
            footer={null}
            onClose={onClose}
            width={MODAL_WIDTH}>
            <Core.Input
                value={searchTerm}
                onChange={setSearchTerm}
                placeholder={t('encrypt.enterCustomerData')}

            />
            <div>
                {showField() ? _.map(fields(), ({ fieldName, label, render }) => {
                    return (
                        <Row key={fieldName} className={css(styles.row)}>
                            <Col xs={12}>
                                <b>
                                    {label}
                                </b>
                                {' '}
                            </Col>
                            <Col xs={3}>{render && _.isFunction(render) ? render(removedData[fieldName]) : removedData[fieldName]}</Col>
                        </Row>
                    );
                }) : null}
            </div>
            <Core.Button
                label={t('encrypt.encrypt')}
                isLoading={isRemovingData}
                onPress={searchAndRemove}
            />
        </Core.Modal>
    );
}

RemoveCustomerDataModal.propTypes = {
    onClose: PropTypes.func.isRequired
};

const styles = StyleSheet.create({
    label: {
        ...fonts.xsLight,
        marginBottom: 2
    },
    value: {
        ...fonts.smNormal,
        color: colors.black,
        marginBottom: spacing.s1
    },
    row: {
        margin: '5px 0',
        color: colors.text,
        width: '100%'
    }
});