import axios from 'axios';

export const endpoints = {
    getCompanies: () => 'company/getAll',
    updateCompany: () => 'company/admin/update',
    deleteCompany: companyId => `company/admin/delete/${companyId}`,
    getCompanyCustomizationUi: companyId => `company/getCustomization/${companyId}`,
    companyCustomizeUi: () => `company/customizeUI`,
    getUsers: companyId => `user/fromcompany/${companyId}`,
    loginAsUser: userId => `auth/admin/loginas/${userId}`,
    revokeAllAuthorizations: userId => `admin/revokeAllAuthorizations/${userId}`,
    unblockUser: userId => `admin/unblockUser/${userId}`,
    getUserActivities: userId => `usersactivity/userActivities/${userId}`,
    getIntegrations: companyId => `company/integrations/${companyId}`,
    getAllIntegrations: () => `admin/allIntegrations`,
    unblockIntegration: () => 'admin/unblockIntegration',
    blockIntegration: () => 'admin/blockIntegration',
    toggleExceededLimitBlockedIntegration: (companyId, integarationId) => `admin/toggleExceededLimitBlockedIntegration/${companyId}/${integarationId}`,
    updateIntegration: () => `admin/updateIntegration`,
    blockMarketSyncIntegration: () => 'admin/blockMarketSyncIntegration',
    unblockMarketSyncIntegration: () => 'admin/unblockMarketSyncIntegration',
    reloginIntegration: (companyId, integarationId) => `admin/reloginIntegration/toggle/${companyId}/${integarationId}`,
    setPrioritySync: (accountId, companyId) => `admin/setPrioritySync/${accountId}/${companyId}`,
    migrateToAmazonSP: (accountId, companyId) => `admin/migrateAmazon/${accountId}/${companyId}`,
    migrateToNativeOutlook: (accountId, companyId) => `admin/migrateSmtpOutlook/${accountId}/${companyId}`,
    getIntegrationLogs: (companyId, accountId) => `admin/integrationLogs/${companyId}/${accountId}`,

    getAllUsers: () => 'admin/allUsers/paged',
    getUserNotifications: () => `admin/userNotifications/paged`,
    createNotificationForAll: () => 'admin/userNotifications/createForAllUsers',
    createNotificationForUsers: () => 'admin/userNotifications/createForSeveralUsers',
    getSentMessages: companyId => `company/getTicketsCountStatistic/${companyId}`,
    downloadTicketListReport: companyId => `company/ticketListCsvReport/${companyId}`,

    getSetupSettings: companyId => `admin/accountSetup/${companyId}`,
    updateSetupSettings: companyId => `admin/updateSetupSetting/${companyId}`,
    setupManualSubsription: companyId => `company/admin/setupManualSubsription/${companyId}`,
    removeSubsription: companyId => `company/admin/removeSubsription/${companyId}`,

    getPromoterScores: () => `promoterScore/paged`,
    getPromoterScoreStats: () => `promoterScore/getNpsStats`,
    downloadNPSReport: () => `promoterScore/csvReport`,
    getMissedNpsCount: () => `promoterScore/getMissedNpsCount`,
    getNpsUserStats: userId => `promoterScore/getNpsUserStats/${userId}`,
    resetNextReviewDateForUser: () => 'promoterScore/resetReviewDate',

    getAdminSettings: () => `admin/adminSettings`,
    saveRepeatSurvey: value => `admin/npsRepeatSurvey/${value}`,
    updateCompanyFeature: (companyId, featureValue) => `company/admin/updateCompanyFeature/${companyId}/${featureValue}`,

    getCompanyRemovedThreads: companyId => `admin/removedThreads/${companyId}`,
    getRemoveThreadLog: (companyId, threadId) => `admin/removedThreadLog/${companyId}/${threadId}`,
    restoreThread: (companyId, threadId) => `admin/restoreThread/${companyId}/${threadId}`,
    importThreadsFromJson: companyId => `admin/importThreads/json/${companyId}`,
    getSubscriptionPlans: companyId => `admin/subscriptionPlans`
};

export const getCompanies = data => {
    return axios.post(endpoints.getCompanies(), { page: 1, count: 10, ...data });
};

export const updateCompany = data => {
    return axios.post(endpoints.updateCompany(), data);
};

export const deleteCompany = companyId => {
    return axios.post(endpoints.deleteCompany(companyId));
};

export const getCompanyCustomizationUi = companyId => {
    return axios.get(endpoints.getCompanyCustomizationUi(companyId));
};

export const companyCustomizeUi = data => {
    return axios.post(endpoints.companyCustomizeUi(), data);
};

export const getUsers = (companyId, params) => {
    return axios.get(endpoints.getUsers(companyId), { params: { page: 1, count: 10, ...params } });
};

export const revokeAllAuthorizations = userId => axios.post(endpoints.revokeAllAuthorizations(userId));

export const loginAsUser = userId => {
    return axios.post(endpoints.loginAsUser(userId));
};
export const unblockUser = userId => {
    return axios.post(endpoints.unblockUser(userId));
};

export const getUserActivities = (userId, params) => {
    return axios.get(endpoints.getUserActivities(userId), { params: { page: 1, count: 10, ...params } });
};

export const getIntegrations = (companyId, params) => {
    return axios.get(endpoints.getIntegrations(companyId), { params: { page: 1, count: 10, ...params } });
};
export const getAllIntegrations = params => {
    return axios.post(endpoints.getAllIntegrations(), { page: 1, count: 20, ...params });
}
export const unblockIntegration = data => {
    return axios.post(endpoints.unblockIntegration(), data);
};
export const blockIntegration = data => {
    return axios.post(endpoints.blockIntegration(), data);
};
export const unblockMarketSyncIntegration = data => {
    return axios.post(endpoints.unblockMarketSyncIntegration(), data);
};
export const blockMarketSyncIntegration = data => {
    return axios.post(endpoints.blockMarketSyncIntegration(), data);
};
export const toggleExceededLimitBlockedIntegration = (companyId, integarationId) => axios.get(endpoints.toggleExceededLimitBlockedIntegration(companyId, integarationId));

export const reloginIntegration = (companyId, integarationId) => {
    return axios.get(endpoints.reloginIntegration(companyId, integarationId));
};
export const updateIntegration = integaration => {
    return axios.post(endpoints.updateIntegration(), integaration);
};
export const setPrioritySync = (integrationId, companyId) => {
    return axios.post(endpoints.setPrioritySync(integrationId, companyId));
};
export const migrateToAmazonSP = (integrationId, companyId) => {
    return axios.post(endpoints.migrateToAmazonSP(integrationId, companyId));
};
export const migrateToNativeOutlook = (integrationId, companyId) => {
    return axios.get(endpoints.migrateToNativeOutlook(integrationId, companyId));
};
export const getIntegrationLogs = (companyId, integrationId, params = {}) => {
    return axios.get(endpoints.getIntegrationLogs(companyId, integrationId), { params: { page: 1, count: 10, ...params } });
};

export const getAllUsers = params => {
    return axios.get(endpoints.getAllUsers(), { params: { page: 1, count: 10, ...params } });
};

export const getUserNotifications = (userId, params) => {
    return axios.post(endpoints.getUserNotifications(), { page: 1, count: 10, ...params, userId });
};
export const createNotificationForAll = (data) => {
    return axios.post(endpoints.createNotificationForAll(), data);
};
export const createNotificationForUsers = (data) => {
    return axios.post(endpoints.createNotificationForUsers(), data);
};

export const getSentMessages = (companyId, data) => {
    return axios.get(endpoints.getSentMessages(companyId), { params: data });
};

export const downloadTicketListReport = (companyId, params) => {
    return axios.get(endpoints.downloadTicketListReport(companyId), { params });
};

export const getSetupSettings = companyId => {
    return axios.get(endpoints.getSetupSettings(companyId));
}

export const updateSetupSetting = (companyId, data) => {
    return axios.post(endpoints.updateSetupSettings(companyId), data);
}

export const setupManualSubsription = (companyId, data) => {
    return axios.post(endpoints.setupManualSubsription(companyId), data);
}
export const removeSubsription = companyId => {
    return axios.get(endpoints.removeSubsription(companyId));
}

export const getPromoterScores = data => {
    return axios.post(endpoints.getPromoterScores(), data);
};
export const getPromoterScoreStats = data => {
    return axios.post(endpoints.getPromoterScoreStats(), data);
};
export const downloadNPSReport = params => {
    return axios.post(endpoints.downloadNPSReport(), params);
};
export const getMissedNpsCount = () => {
    return axios.post(endpoints.getMissedNpsCount());
};
export const getNpsUserStats = userId => {
    return axios.get(endpoints.getNpsUserStats(userId));
};
export const resetNextReviewDateForUser = data => {
    return axios.post(endpoints.resetNextReviewDateForUser(), data);
};

export const getAdminSettings = () => {
    return axios.get(endpoints.getAdminSettings());
};
export const saveRepeatSurvey = value => {
    return axios.post(endpoints.saveRepeatSurvey(value));
};

export const updateCompanyFeature = (companyId, featureValue) => {
    return axios.post(endpoints.updateCompanyFeature(companyId, featureValue));
};

export const getCompanyRemovedThreads = (companyId, params = {}) => {
    return axios.get(endpoints.getCompanyRemovedThreads(companyId), { params: { page: 1, count: 10, ...params } });
};
export const getRemoveThreadLog = (companyId, threadId) => {
    return axios.get(endpoints.getRemoveThreadLog(companyId, threadId));
};
export const restoreThread = (companyId, threadId) => {
    return axios.post(endpoints.restoreThread(companyId, threadId));
};

export const importThreadsFromJson = (companyId, data) => {
    return axios.post(endpoints.importThreadsFromJson(companyId), data);
};

export const getSubscriptionPlans = () => {
    return axios.get(endpoints.getSubscriptionPlans());
};