import React from "react";
import { Menu } from "antd"; // use it because current tooltip doesn't work good with ant menu
import { SidebarItemView } from "./SiderbarItemView";

export const SidebarItem = ({
    label,
    route,
    ...props
}) => (
    <Menu.Item key={route} title={label} {...props}>
        <SidebarItemView label={label} route={route} {...props} />
    </Menu.Item>
);