import React, { createContext, useCallback, useContext, useState } from "react";

const LayoutContext = createContext();

const LayoutContextProvider = ({ children }) => {
    const [isCollapsedMenu, setIsCollapsedMenu] = useState(false);

    const toggleMenu = useCallback(() => setIsCollapsedMenu(prev => !prev), []);

    return (
        <LayoutContext.Provider value={{
            isCollapsedMenu,
            toggleMenu
        }}>
            {children}
        </LayoutContext.Provider>
    );
};

const useLayoutContext = () => useContext(LayoutContext);

export {
    LayoutContextProvider,
    useLayoutContext
}