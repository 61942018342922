import React from 'react';
import { Layout } from 'antd';
import { useLayoutContext } from '../LayoutContext';
import { LAYOUT } from '../constants';
import { colors, css, spacing, StyleSheet } from '../../styles';
import { SidebarMenu } from './SidebarMenu';
import { SidebarLogo } from './SidebarLogo';
import { SidebarFooter } from './SidebarFooter';

export const Sidebar = () => {
    const { isCollapsedMenu } = useLayoutContext();
    return (
        <Layout.Sider
            trigger={null}
            collapsible={true}
            collapsed={isCollapsedMenu}
            width={LAYOUT.SIDEBAR_WIDTH}
            collapsedWidth={LAYOUT.SIDEBAR_WIDTH_COLLAPSED}
            className={css(styles.container)}>
            <SidebarLogo />
            <div className={css(styles.scrollableContainer)}>
                <SidebarMenu />
                <SidebarFooter />
            </div>
        </Layout.Sider>
    )
};

const styles = StyleSheet.create({
    container: {
        overflow: 'hidden',
        background: `${colors.white} !important`,
        height: '100vh',
        boxShadow: `0 0 ${spacing.s1}px 0 ${colors.shadow}`,
        zIndex: 3,
        '@media (max-width: 700px)': {
            position: 'fixed',
            zIndex: 50
        },
        '& .ant-layout-sider-children': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    scrollableContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden'
    }
});