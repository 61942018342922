import _ from 'lodash';
import { createSelector } from "reselect";
import { NAME, ENTERPRISE_PLAN_IDS } from './constants';
import * as actionTypes from './actionTypes';
import { selectors as coreSelectors } from '../core';


// companies
const getCompaniesStore = state => state[NAME].companies;
const getCompaniesItems = state => getCompaniesStore(state).items;
const getCompaniesIds = state => getCompaniesStore(state).ids;

export const getCompanies = createSelector(getCompaniesItems, getCompaniesIds, (items, ids) => _.map(ids, id => items[id]));
export const getCompaniesPagination = state => getCompaniesStore(state).pagination;
export const isGettingCompanies = state => coreSelectors.isLoading(state, actionTypes.GET_COMPANIES.NAME);
export const getCompany = (state, companyId) => getCompaniesItems(state)[companyId];
export const getCompanyFeatures = (state, companyId) => _.get(getCompany(state, companyId), 'companyFeatures', []);
export const isUpdatingCompany =
    (state, companyId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.UPDATE_COMPANY.NAME, companyId);
export const isDeletingCompany =
    (state, companyId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.DELETE_COMPANY.NAME, companyId);
export const isSettingManualSubscription =
    (state, companyId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.SETUP_MANUAL_SUBSCRIPTION.NAME, companyId);
export const isGettingCustomization =
    (state, companyId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_COMPANY_CUSTOMIZATION_UI.NAME, companyId);


//users
const getUsersStore = state => state[NAME].users;
const getUsersItems = state => getUsersStore(state).items;
const getUsersByCompanyStore = (state, companyId) => getUsersStore(state).companies[companyId];
const getUsersIds = (state, companyId) => _.get(getUsersByCompanyStore(state, companyId), 'ids', []);
const getUserLoginsStore = state => getUsersStore(state).logins;
const getUserLoginsItems = state => getUserLoginsStore(state).items;

export const getUsersByCompany = createSelector(getUsersItems, getUsersIds, (items, ids) => _.map(ids, id => items[id]));
export const getUsersPagination = createSelector(getUsersByCompanyStore, store => _.get(store, 'pagination', {}));
export const isGettingUsers = (state, companyId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_USERS.NAME, companyId);
export const getUser = (state, userId) => getUsersItems(state)[userId];
export const getUserLogin = (state, userId) => getUserLoginsItems(state)[userId];
export const isLoginingAsUser = (state, userId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.LOGIN_AS_USER.NAME, userId);
export const isUnblockingUser = (state, userId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.UNBLOCK_USER.NAME, userId);
export const isRevokeAllAuthorizations = (state, userId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.REVOKE_ALL_USER_SESSIONS.NAME, userId);


//user activities
const getActivitiesStore = state => state[NAME].activities;
const getActivitiesItems = state => getActivitiesStore(state).items;
const getActivitiesByUserStore = (state, userId) => getActivitiesStore(state).users[userId];
const getActivitiesIds = (state, userId) => _.get(getActivitiesByUserStore(state, userId), 'ids', []);

export const getActivitiesByUser = createSelector(getActivitiesItems, getActivitiesIds, (items, ids) => _.map(ids, id => items[id]));
export const getActivitiesPagination = createSelector(getActivitiesByUserStore, store => _.get(store, 'pagination', {}));
export const isGettingUserActivities = (state, userId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_USER_ACTIVITIES.NAME, userId);
export const getUserActivity = (state, activityId) => getActivitiesItems(state)[activityId];


//integrations
const getIntegrationsStore = state => state[NAME].integrations;
const getIntegrationsItems = state => getIntegrationsStore(state).items;
const getIntegrationsByCompanyStore = (state, companyId) => getIntegrationsStore(state).companies[companyId];
const getIntegrationsIds = (state, companyId) => _.get(getIntegrationsByCompanyStore(state, companyId), 'ids', []);

export const getIntegrationsByCompany = createSelector(getIntegrationsItems, getIntegrationsIds, (items, ids) => _.map(ids, id => items[id]));
export const getIntegrationsPagination = createSelector(getIntegrationsByCompanyStore, store => _.get(store, 'pagination', {}));
export const isGettingIntegrations = (state, companyId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_INTEGRATIONS.NAME, companyId);
export const getIntegrationForCompany = (state, integrationId) => getIntegrationsItems(state)[integrationId];
export const isUnblockingIntegration = (state, integrationId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.UNBLOCK_INTEGRATION.NAME, integrationId) || coreSelectors.isLoadingWithRequestId(state, actionTypes.UNBLOCK_MARKET_SYNC_INTEGRATION.NAME, integrationId) ;
export const isBlockingIntegration = (state, integrationId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.BLOCK_INTEGRATION.NAME, integrationId) || coreSelectors.isLoadingWithRequestId(state, actionTypes.BLOCK_MARKET_SYNC_INTEGRATION.NAME, integrationId) ;
export const isSettingReloginIntegration = (state, integrationId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.RELOGIN_INTEGRATION.NAME, integrationId);
export const isTogglingExceededLimitBlockedIntegration = (state, integrationId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.TOGGLE_EXCEEDED_LIMIT_BLOCKED_INTEGRATION.NAME, integrationId);
export const isUpdatingIntegration = (state, integrationId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.UPDATE_INTEGRATION.NAME, integrationId);
export const isMigratingToAmazonSP = (state, integrationId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.MIGRATE_TO_AMAZON_SP.NAME, integrationId);
export const isMigratingToNativeOutlook = (state, integrationId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.MIGRATE_TO_NATIVE_OUTLOOK.NAME, integrationId);

//allIntegrations
const getAllIntegrationsStore = state => state[NAME].allIntegrations;
const getAllIntegrationsItems = state => getAllIntegrationsStore(state).items;
const getAllIntegrationsIds = state => _.get(getAllIntegrationsStore(state), 'ids', []);
export const getIntegration = (state, integrationId) => getAllIntegrationsItems(state)[integrationId];

export const getAllIntegrations = createSelector(getAllIntegrationsItems, getAllIntegrationsIds, (items, ids) => _.map(ids, id => items[id]));
export const getAllIntegrationsPagination = createSelector(getAllIntegrationsStore, store => _.get(store, 'pagination', {}));
export const isGettingAllIntegrations = state => coreSelectors.isLoading(state, actionTypes.GET_ALL_INTEGRATIONS.NAME);

//integration logs
const getIntegrationLogsStore = state => state[NAME].integrationLogs;
const getIntegrationLogsByIntegrationStore = (state, integrationId) => getIntegrationLogsStore(state)[integrationId];
const getIntegrationLogsByIntegrationItems = createSelector(getIntegrationLogsByIntegrationStore, store => _.get(store, 'items', {}));
const getIntegrationLogsByIntegrationIds = createSelector(getIntegrationLogsByIntegrationStore, store => _.get(store, 'ids',  []));

export const getIntegrationLogs =
    createSelector(getIntegrationLogsByIntegrationItems, getIntegrationLogsByIntegrationIds, (items, ids) => _.map(ids, id => items[id]));
export const getIntegrationLogsPagination =
    createSelector(getIntegrationLogsByIntegrationStore, store => _.get(store, 'pagination', {}));
export const isLoadingIntegrationLogs = (state, integrationId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_INTEGRATION_LOGS.NAME, integrationId);

//allUsers
const getAllUsersStore = state => state[NAME].allUsers;
const getAllUsersItems = state => getAllUsersStore(state).items;
const getAllUsersIds = state => _.get(getAllUsersStore(state), 'ids', []);

export const getUserFromAll = (state, userId) => getAllUsersItems(state)[userId];
export const getAllUsers = createSelector(getAllUsersItems, getAllUsersIds, (items, ids) => _.map(ids, id => items[id]));
export const getAllUsersPagination = createSelector(getAllUsersStore, store => _.get(store, 'pagination', {}));
export const isGettingAllUsers = state => coreSelectors.isLoading(state, actionTypes.GET_ALL_USERS.NAME);


//user notifications
const getNotificationsStore = state => state[NAME].notifications;
const getNotificationsItems = state => getNotificationsStore(state).items;
const getNotificationsByUserStore = (state, userId) => getNotificationsStore(state).users[userId];
const getNotificationsIds = (state, userId) => _.get(getNotificationsByUserStore(state, userId), 'ids', []);

export const getNotificationsByUser = createSelector(getNotificationsItems, getNotificationsIds, (items, ids) => _.map(ids, id => items[id]));
export const getNotificationsPagination = createSelector(getNotificationsByUserStore, store => _.get(store, 'pagination', {}));
export const isGettingUserNotifications = (state, userId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_USER_NOTIFICATIONS.NAME, userId);
export const isSendingNotificationForAll = state => coreSelectors.isLoading(state, actionTypes.CREATE_NOTIFICATION_FOR_ALL.NAME);
export const isSendingNotificationForUsers = state => coreSelectors.isLoading(state, actionTypes.CREATE_NOTIFICATION_FOR_USERS.NAME);


//sentMessages
const getSentMessagesStore = state => state[NAME].sentMessages;
export const getCompaniesSentMessages = (state, companyId) => getSentMessagesStore(state).companies[companyId];
export const isGettingSentMessages = (state, companyId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_SENT_MESSAGES.NAME, companyId);


//reports
export const isDownloadingTicketListReport =
    (state, companyId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.DOWNLOAD_TICKETS_LIST_REPORT.NAME, companyId);

//setupSettings
const getSetupSettingsStore = state => state[NAME].setupSettings;
const getCompanySetupSetting = (state, companyId) => getSetupSettingsStore(state).companies[companyId];
export const getCompaniesSetupSettingsLabels = createSelector(getCompanySetupSetting, settings => _.get(settings, 'labels'));
export const getCompaniesSetupSettingsRules = createSelector(getCompanySetupSetting, settings => _.get(settings, 'rules'));
export const getCompaniesSetupSettingsTemplates = createSelector(getCompanySetupSetting, settings => _.get(settings, 'templates'));
export const getCompaniesSetupSettingsAutoResponses = createSelector(getCompanySetupSetting, settings => _.get(settings, 'autoResponses'));
export const getCompaniesSetupSettingsCustomFields = createSelector(getCompanySetupSetting, settings => _.get(settings, 'customFields'));

export const isLoadingSetupSettings = (state, companyId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_SETUP_SETTINGS.NAME, companyId);

export const isLoadingSaveSetupSettings = (state, companyId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.UPDATE_SETUP_SETTING.NAME, companyId);

//subscription plans
export const isEnterprise = (state, planId) => planId ? _.includes(ENTERPRISE_PLAN_IDS, planId.toUpperCase()) : false;

//feedbacks
const getPromoterScoresStore = state => state[NAME].promoterScores;
const getPromoterScoresItems = state => getPromoterScoresStore(state).items;
const getPromoterScoresIds = state => getPromoterScoresStore(state).ids;

export const getPromoterScores = createSelector(getPromoterScoresItems, getPromoterScoresIds, (items, ids) => _.map(ids, id => items[id]));
export const getPromoterScoresPagination = state => getPromoterScoresStore(state).pagination;
export const getPromoterScoresStats = state => getPromoterScoresStore(state).stats;
export const getMissedNpsCount = state => getPromoterScoresStore(state).missedNpsCount;
export const getPromoterScoresAllStats = createSelector(getPromoterScoresStats, stats => {
    const scoresCount = _.sumBy(stats, 'scoresCount');
    const promotersCount = _.sumBy(stats, 'promotersCount');
    const detractorsCount = _.sumBy(stats, 'detractorsCount');
    const passivesCount = _.sumBy(stats, 'passivesCount');
    const nps = _.sumBy(stats, 'nps');
    return {
        scoresCount,
        promotersCount,
        detractorsCount,
        passivesCount,
        nps,
        promotersPerc: Math.round(promotersCount/scoresCount*100),
        detractorsPerc: Math.round(detractorsCount/scoresCount*100),
        passivesPerc: Math.round(passivesCount/scoresCount*100),
        npsPerc: Math.round(nps/scoresCount*100),
    };
});

export const isLoadingPromoterScores = state => coreSelectors.isLoading(state, actionTypes.GET_PROMOTER_SCORES.NAME);
export const isLoadingPromoterScoreStats = state => coreSelectors.isLoading(state, actionTypes.GET_PROMOTER_SCORE_STATS.NAME);
export const isDownloadingNPSReport = state => coreSelectors.isLoadingWithRequestId(state, actionTypes.DOWNLOAD_NPS_REPORT.NAME);
export const isGettingMissedNpsCount = state => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_MISSED_NPS_COUNT.NAME);
export const getCommentsNps = state => getPromoterScoresStore(state).commentsByUser;
export const isResettingNPSForUsers = state => coreSelectors.isLoading(state, actionTypes.RESET_NEXT_REVIEW_DATE_FOR_USERS.NAME);

// admin settings
export const getAdminSettings = state => state[NAME].adminSettings;
export const getNpsRepeatSurvey = createSelector(getAdminSettings, data => _.get(data, 'npsRepeatSurvey'));
export const isLoadingAdminSettings = state => coreSelectors.isLoading(state, actionTypes.GET_ADMIN_SETTINGS.NAME);
export const isSavingRepeatSurvey = state => coreSelectors.isLoading(state, actionTypes.SAVE_REPEAT_SURVEY.NAME);

// removed threads
const getRemovedThreadsStore = state => state[NAME].removedThreads;
const getRemovedThreadsByCompanyStore = (state, companyId) => getRemovedThreadsStore(state)[companyId];
const getRemovedThreadsByCompanyItems = createSelector(getRemovedThreadsByCompanyStore, store => _.get(store, 'items', {}));
const getRemovedThreadsByCompanyIds = createSelector(getRemovedThreadsByCompanyStore, store => _.get(store, 'ids',  []));

export const getRemovedThreads =
    createSelector(getRemovedThreadsByCompanyItems, getRemovedThreadsByCompanyIds, (items, ids) => _.map(ids, id => items[id]));
export const getRemovedThread =
    createSelector(getRemovedThreadsByCompanyItems, coreSelectors.getThirdParam, (items, id) => _.get(items, id));
export const getRemovedThreadsPagination =
    createSelector(getRemovedThreadsByCompanyStore, store => _.get(store, 'pagination', {}));
export const isLoadingRemovedThreads = (state, companyId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_COMPANY_REMOVED_THREADS.NAME, companyId);
export const isRestoringThread = (state, threadId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.RESTORE_THREAD.NAME, threadId);

// imported threads
const getImportedThreadsStore = state => state[NAME].importedThreads;
const getImportedThreadsByCompanyStore = (state, companyId) => getImportedThreadsStore(state)[companyId];

export const getTotalThreadsToImportCount = createSelector(getImportedThreadsByCompanyStore, store => _.get(store, 'totalCount', 0));
export const getImportedSuccessfullyThreadsCount = createSelector(getImportedThreadsByCompanyStore, store => _.get(store, 'importedSuccessfullyCount', 0));
export const getImportThreadErrors = createSelector(getImportedThreadsByCompanyStore, store => _.get(store, 'errors', []));
export const getBadThreadsJson = createSelector(getImportedThreadsByCompanyStore, store => _.get(store, 'badThreads', null));

export const isImportingThreads = (state, companyId) => coreSelectors.isLoadingWithRequestId(state, actionTypes.IMPORT_TREADS_FROM_JSON.NAME, companyId);

//subscriptionPlans
const getSubscriptionPlansStore = state => state[NAME].subscriptionPlans;
const getSubscriptionPlansItems = createSelector(getSubscriptionPlansStore, store => _.get(store, 'items', {}));
const getSubscriptionPlansIds = createSelector(getSubscriptionPlansStore, store => _.get(store, 'ids', []));

export const getSubscriptionPlans =
    createSelector(getSubscriptionPlansItems, getSubscriptionPlansIds, (items, ids) => _.map(ids, id => items[id]));