import React from 'react'
import { colors, css, fonts, spacing, StyleSheet } from '../../styles';
import { components as Core, ICONS, useToggleState, useTranslation } from '../../modules/core';
import { RemoveCustomerDataModal, CreateNewEmailAccountModal } from '../../modules/shared';

export const TopbarAdditionalFeatures = () => {
    const { t } = useTranslation();
    const [isVisibleEncryptModal, onShowEncryptModal, onCloseEncryptModal] = useToggleState(false);
    const [isVisibleCreateEmailModal, onShowCreateEmailModal, onCloseCreateEmailModal] = useToggleState(false);

    const getItems = () => ([{
        label: t('createNewEmail.createEmail'),
        icon: ICONS.plusCircle,
        onClick: onShowCreateEmailModal
    }, {
        label: t('encrypt.encrypt'),
        icon: ICONS.remove,
        onClick: onShowEncryptModal
    }]);

    const renderItem = ({ label, icon, onClick }) => (
        <div key={label} className={css(styles.popoverItem)} onClick={onClick}>
            <Core.Icon
                type={icon}
                color={colors.textColor}
                size={spacing.s3}
            />
            <p className={css(styles.popoverItemText)}>
                {label}
            </p>
        </div>
    );

    const renderContent = () => getItems().map(renderItem);

    return (
        <div className={css(styles.container)}>
            <Core.Popover
                placement="bottomRight"
                trigger="hover"
                content={renderContent()}>
                <Core.Icon
                    color={colors.blackOpacity}
                    size={spacing.s4}
                    type={ICONS.pushPin}
                />
            </Core.Popover>

            {isVisibleEncryptModal && (
                <RemoveCustomerDataModal onClose={onCloseEncryptModal} />
            )}
            {isVisibleCreateEmailModal && (
                <CreateNewEmailAccountModal onClose={onCloseCreateEmailModal} />
            )}
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: spacing.s5,
        paddingRight: spacing.s5,
        borderLeft: `1px solid ${colors.greyLight}`,
    },
    popoverContainer: {
    },
    popoverItem: {
        display: 'flex',
        alignItems: 'center',
        padding: spacing.s2,
        minWidth: 100,
        ...fonts.xsRegular,
        ':hover': {
            backgroundColor: colors.primaryBackground
        }
    },
    popoverItemText: {
        paddingLeft: spacing.s1,
        ...fonts.xsNormal
    }
});
