export const NAME = 'core';
export const LOADING_NAME = 'loading';
export const ERROR_NAME = 'error';

export const BASE_URL = 'https://api.replyco.com/api/';
export const MAIN_APP_URL = 'https://app.replyco.com';
export const LOGIN_AS_USER_URL = `${MAIN_APP_URL}/admin/signin`;

export const ROUTES = {
    home: () => '/'
};

export const TOAST_TYPES = {
    serverUnavailable: 'serverUnavailable',
    cannotSendAnEmptyNotification: 'cannotSendAnEmptyNotification'
};

export const ERROR_TYPES = {
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    _404: '_404'
};

export const DATE_FORMATS = {
    DATE: 'L',
    DATE_ANT: 'MM/DD/YYYY',
    DATE_TIME: 'MM/DD/YYYY LT',
    YEAR: 'YYYY',
    DAY_MONTH: 'DD.MM',
    UTC: "YYYY-MM-DDTHH:mm:ss.x",
};

export const SORT_DIRECTIONS = {
    ascend: 'ascend',
    descend: 'descend'
};

export const BOTH_SORT_DIRECTIONS = [
    SORT_DIRECTIONS.ascend,
    SORT_DIRECTIONS.descend
];

export const FIELD_TYPES = {
    INPUT: 1,
    DATE_PICKER: 2,
    CHECKBOX: 3,
    SELECT: 4,
    INPUT_NUMBER: 5,
    COLLAPSE: 6
};

export const COMPANY_THEME = {
    primary: "primary",
    error: "error",
    success: "success",
    warning: "warning"
};

export const BUTTON_TYPES = {
    primary: 'primary',
    blueOutline: 'blueOutline',
    redOutline: 'redOutline'
};

export const PERIODS = {
    today: 'today',
    yesterday: 'yesterday',
    last7Days: 'last7Days',
    last30Days: 'last30Days',
    last90Days: 'last90Days',
    allTime: 'All time',
    custom: 'custom'
};

export const MARKETPLACES = [
    "Amazon",
    "Ebay",
    "Etsy",
    "Email",
    "BigCommerce",
    "WooCommerce",
    "Magento",
    "Shopify",
    "Linnworks",
    "",
    "Gmail",
    "Telegram",
    "",
    "Cdiscount",
    "Facebook",
    "Fruugo",
    "Amazon v2",
    "Instagram",
    "Wish",
    "ChannelAdvisor",
    "", //custom integrations
    "Outlook",
    "Veeqo",
    "Aircall",
    "Katana",
    "Billbee",
    "Kaufland",
    "WhatsApp",
    "ShopLine",
    "Brightpearl"
];

export const MARKETPLACES_ENUM = {
    Amazon: 0,
    Ebay: 1,
    Etsy: 2,
    Email: 3,
    BigCommerce: 4,
    WooCommerce: 5,
    Magento: 6,
    Shopify: 7,
    Linnworks: 8,
    Gmail: 10,
    Telegram: 11,
    Widget: 12,
    Cdiscount: 13,
    Facebook: 14,
    Fruugo: 15,
    AmazonSP: 16,
    Instagram: 17,
    Wish: 18,
    ChannelAdvisor: 19,
    Outlook: 21,
    Veeqo: 22,
    Aircall: 23,
    Katana: 24,
    Billbee: 25,
    Kaufland: 26,
    WhatsApp: 27,
    ShopLine: 28,
    Brightpearl: 29
};

export const MARKETPLACES_WITH_RELOGIN = [
    MARKETPLACES_ENUM.Ebay,
    MARKETPLACES_ENUM.Gmail,
    MARKETPLACES_ENUM.Amazon,
    MARKETPLACES_ENUM.WooCommerce,
    MARKETPLACES_ENUM.Shopify,
    MARKETPLACES_ENUM.Cdiscount,
    MARKETPLACES_ENUM.Fruugo,
    MARKETPLACES_ENUM.Email,
    MARKETPLACES_ENUM.AmazonSP,
    MARKETPLACES_ENUM.Etsy,
    MARKETPLACES_ENUM.Wish,
    MARKETPLACES_ENUM.Outlook,
    MARKETPLACES_ENUM.Aircall,
    MARKETPLACES_ENUM.ShopLine
];

export const MARKETPLACES_WITH_TICKETS = [
    MARKETPLACES_ENUM.Amazon,
    MARKETPLACES_ENUM.Ebay,
    MARKETPLACES_ENUM.Email,
    MARKETPLACES_ENUM.WooCommerce,
    MARKETPLACES_ENUM.BigCommerce,
    MARKETPLACES_ENUM.Magento,
    MARKETPLACES_ENUM.Shopify,
    MARKETPLACES_ENUM.Gmail,
    MARKETPLACES_ENUM.Cdiscount,
    MARKETPLACES_ENUM.Fruugo,
    MARKETPLACES_ENUM.AmazonSP,
    MARKETPLACES_ENUM.Etsy,
    MARKETPLACES_ENUM.Wish,
    MARKETPLACES_ENUM.Outlook,
    MARKETPLACES_ENUM.ShopLine
];

export const CHAT_MARKETPLACES = [
    MARKETPLACES_ENUM.Telegram,
    MARKETPLACES_ENUM.Facebook,
    MARKETPLACES_ENUM.Instagram,
    MARKETPLACES_ENUM.WhatsApp
];

export const INTEGRATION_TYPES = {
    marketplace: 0,
    instantMessages: 1,
    all: 2
};