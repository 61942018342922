import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { components as Core, useTranslation, FIELD_TYPES, Toast } from '../../../core';
import * as selectors from '../../selectors';
import { createNewEmailAccount } from "../../actions";
import { EmailProtocolType } from "../../constants";
import { StyleSheet, css, colors, fonts, spacing, common } from '../../../../styles';

const FIELDS = {
    email: 'email',
    password: 'password',
    protocolAddress: 'protocolAddress',
    protocolPort: 'protocolPort',
    emailProtocolType: 'protocolType',
    protocolUseSSL: 'protocolUseSSL',
    smtpServer: 'smtpServer',
    smtpPort: 'smtpPort',
    smtpUseSSL: 'smtpUseSSL'
};

const MODAL_WIDTH = 400;

export const CreateNewEmailAccountModal = ({ onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const newEmailAccount = useSelector(selectors.getNewEmailAccount);
    const isCreatingNewEmailAccount = useSelector(selectors.isCreatingNewEmailAccount);

    const fields = useCallback(() => [{
        fieldName: FIELDS.email,
        label: t('email')
    }, {
        fieldName: FIELDS.password,
        label: t('password')
    }, {
        fieldName: FIELDS.protocolAddress,
        label: t('createNewEmail.protocolAddress')
    }, {
        fieldName: FIELDS.protocolPort,
        label: t('createNewEmail.protocolPort')
    }, {
        fieldName: FIELDS.emailProtocolType,
        label: t('createNewEmail.protocolType'),
        render: value => EmailProtocolType[value]
    }, {
        fieldName: FIELDS.protocolUseSSL,
        label: t('createNewEmail.protocolUseSSL'),
        fieldType: FIELD_TYPES.CHECKBOX
    }, {
        fieldName: FIELDS.smtpServer,
        label: t('createNewEmail.smtpServer')
    }, {
        fieldName: FIELDS.smtpPort,
        label: t('createNewEmail.smtpPort')
    }, {
        fieldName: FIELDS.smtpUseSSL,
        label: t('createNewEmail.smtpUseSSL'),
        fieldType: FIELD_TYPES.CHECKBOX
    }], []);

    const onCreate = () => dispatch(createNewEmailAccount());

    const copyToClipboard = () => {
        let str = "";
        _.mapKeys(newEmailAccount, (value, key) => {
            if (key === FIELDS.emailProtocolType) {
                str+= `${t(`createNewEmail.${key}`)}: ${_.toString(EmailProtocolType[value])} \n`;
            } else {
                str+= `${t(`createNewEmail.${key}`)}: ${_.toString(value)} \n`;
            }
        });
        navigator.clipboard.writeText(str);
        Toast.success();
    };

    const getCopyToClipBoardButton = () => (
        <Core.Button
            label={t('copyToClipboard')}
            onPress={copyToClipboard}
        />
    );

    return (
        <Core.Modal
            isVisible={true}
            onClose={onClose}
            footer={!_.isEmpty(newEmailAccount) ? getCopyToClipBoardButton() : null}
            width={MODAL_WIDTH}>
            {_.isEmpty(newEmailAccount) ? (
                <div className={css(common.flexColCenter)}>
                    {isCreatingNewEmailAccount ? (
                        <Core.Spinner />
                    ) : (
                        <Core.Button
                            label={t('createNewEmail.createEmail')}
                            isLoading={isCreatingNewEmailAccount}
                            onPress={onCreate}
                        />
                    )}
                </div>
            ) : (
                <div className={css(styles.row)}>
                    <Core.FieldsGenerator
                        fields={fields()}
                        data={newEmailAccount}
                    />
                </div>
            )}
        </Core.Modal>
    );
}

CreateNewEmailAccountModal.propTypes = {
    onClose: PropTypes.func.isRequired
};

const styles = StyleSheet.create({
    label: {
        ...fonts.xsLight,
        marginBottom: 2
    },
    value: {
        ...fonts.smNormal,
        color: colors.black,
        marginBottom: spacing.s1
    },
    row: {
        margin: spacing.s3,
        color: colors.text
    }
});