import React from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { LayoutContextProvider } from './LayoutContext';
import { colors, css, spacing, StyleSheet } from '../styles';
import { LAYOUT } from './constants';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';
import { selectors } from '../modules/auth';
import { AuthPage } from '../pages';

const { Content } = Layout;

export const AppLayout = ({ children }) => {
    const isLoggedIn = useSelector(selectors.isLoggedIn);

    if (!isLoggedIn) {
        return (
            <AuthPage />
        );
    }

    return (
        <LayoutContextProvider>
            <Layout className={css(styles.mainContainer)}>
                <Sidebar />
                <Layout>
                    <Topbar />
                    <Content className={css(styles.contentContainer)}>{children}</Content>
                </Layout>
            </Layout>
        </LayoutContextProvider>
    )
};

const styles = StyleSheet.create({
    mainContainer: {
        height: '100vh',
        '@media print': {
            height: 'auto'
        },
        '& a': {
            color: colors.primary
        }
    },
    contentContainer: {
        position: 'relative',
        marginLeft: spacing.s4,
        marginRight: spacing.s4,
        marginTop: LAYOUT.TOPBAR_HEIGHT,
        '@media (max-width: 700px)': {
            marginLeft: LAYOUT.SIDEBAR_WIDTH_COLLAPSED
        },
        '@media print': {
            marginTop: 0,
            marginLeft: 0
        }
    }
});
