import axios from 'axios';

export const endpoints = {
    getOverview: () => `adminReporting/overview`,
    getRegistrations: () => `adminReporting/registrations/paged`,
    downloadRegistrationsReport: () => `adminReporting/registrations/csvReport`,
    getRegistrationsChartData: () => `adminReporting/registrations/chartData`,

    getUsageReportData: () => `adminReporting/usageReport/paged`,

    getCancellations: () => `adminReporting/cancellations/paged`,
    getCancellationsChartData: () => `adminReporting/cancellations/chartData`,
    getCancelaltionsOverview: () => 'adminReporting/cancellations/overview',

    getSyncJobsData: () => 'adminReporting/syncJobs',
    removeSyncJobsData: () => 'admin/syncJob/remove',
};

export const getOverview = data => axios.post(endpoints.getOverview(), data);
export const getRegistrations = data => axios.post(endpoints.getRegistrations(), { page: 1, count: 10, ...data });
export const downloadRegistrationsReport = data => axios.post(endpoints.downloadRegistrationsReport(), data);
export const getRegistrationsChartData = data => axios.post(endpoints.getRegistrationsChartData(), data);

export const getUsageReportData = data => axios.post(endpoints.getUsageReportData(), { page: 1, count: 50, ...data });

export const getCancellations = data => axios.post(endpoints.getCancellations(), { page: 1, count: 10, ...data });
export const getCancelaltionsOverview = data => axios.post(endpoints.getCancelaltionsOverview(), data);
export const getCancellationsChartData = data => axios.post(endpoints.getCancellationsChartData(), data);
export const getSyncJobsData = () => axios.get(endpoints.getSyncJobsData());
export const removeSyncJobsData = data => axios.post(endpoints.removeSyncJobsData(), data);
