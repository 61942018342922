import React from 'react'
import { colors, css, spacing, StyleSheet } from '../../styles';
import { useLayoutContext } from '../LayoutContext';
import { LAYOUT } from '../constants';
import { images } from '../../assets';

export const SidebarLogo = () => {
    const { isCollapsedMenu } = useLayoutContext();

    return (
        <div className={css(styles.container)}>
            <img
                src={isCollapsedMenu ? images.LogoMin : images.LogoFull}
                alt='Replyco Admin'
                className={css(isCollapsedMenu ? styles.miniLogoSize : styles.defaultLogoSize)}
            />
        </div>
    );
};

const styles = StyleSheet.create({
    container: {
        background: `${colors.white} !important`,
        transition: 'background-color 0s',
        height: LAYOUT.TOPBAR_HEIGHT,
        boxSizing: 'content-box',
        borderBottom: `1px solid ${colors.greyLight}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    defaultLogoSize: {
        height: spacing.s14
    },
    miniLogoSize: {
        height: spacing.s6
    }
})