import * as actionTypes from "./actionTypes";
import { Toast } from "./services";

export function addError(actionType, error) {
    return function (dispatch) {
        dispatch({
            type: actionTypes.ADD_ERROR,
            payload: {
                actionType,
                error
            }
        });
    }
}

export function removeError(actionType) {
    return function (dispatch) {
        dispatch({
            type: actionTypes.REMOVE_ERROR,
            payload: {
                actionType
            }
        });
    }
}

export const baseRequest = ({
    actionType,
    apiCall
}) => data => dispatch => {
    dispatch({ type: actionType.REQUEST });
    return apiCall(data)
        .then(response => {
            dispatch({
                type: actionType.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionType.ERROR });
        });
};


export const baseRequestWithId = ({
    requestId,
    actionType,
    apiCall,
    data
}) => () => dispatch => {
    dispatch({ type: actionType.REQUEST, requestId });
    return apiCall(data)
        .then(response => {
            dispatch({
                type: actionType.SUCCESS,
                requestId,
                payload: response.data
            });
        })
        .catch(error => {
            Toast.error(error);
            dispatch({ type: actionType.ERROR, requestId });
        });
};