import { createActionType } from '../core';
import { NAME } from './constants';

export const GET_COMPANIES = createActionType(`${NAME}/GET_COMPANIES`);
export const UPDATE_COMPANY = createActionType(`${NAME}/UPDATE_COMPANY`);
export const GET_COMPANY_CUSTOMIZATION_UI = createActionType(`${NAME}/GET_COMPANY_CUSTOMIZATION_UI`);
export const COMPANY_CUSTOMIZE_UI = createActionType(`${NAME}/COMPANY_CUSTOMIZE_UI`);
export const DELETE_COMPANY = createActionType(`${NAME}/DELETE_COMPANY`);

export const GET_USERS = createActionType(`${NAME}/GET_USERS`);
export const LOGIN_AS_USER = createActionType(`${NAME}/LOGIN_AS_USER`);
export const REVOKE_ALL_USER_SESSIONS = createActionType(`${NAME}/REVOKE_ALL_USER_SESSIONS`);
export const CLEAR_LOGIN_AS_USER = `${NAME}/CLEAR_LOGIN_AS_USER`;
export const UNBLOCK_USER = createActionType(`${NAME}/UNBLOCK_USER`);
export const GET_USER_ACTIVITIES = createActionType(`${NAME}/GET_USER_ACTIVITIES`);

export const GET_INTEGRATIONS = createActionType(`${NAME}/GET_INTEGRATIONS`);
export const GET_ALL_INTEGRATIONS = createActionType(`${NAME}/GET_ALL_INTEGRATIONS`);
export const UNBLOCK_INTEGRATION = createActionType(`${NAME}/UNBLOCK_INTEGRATION`);
export const BLOCK_INTEGRATION = createActionType(`${NAME}/BLOCK_INTEGRATION`);
export const UNBLOCK_MARKET_SYNC_INTEGRATION = createActionType(`${NAME}/UNBLOCK_MARKET_SYNC_INTEGRATION`);
export const BLOCK_MARKET_SYNC_INTEGRATION = createActionType(`${NAME}/BLOCK_MARKET_SYNC_INTEGRATION`);
export const NEED_RELOGIN_INTEGRATION = createActionType(`${NAME}/NEED_RELOGIN_INTEGRATION`);
export const RELOGIN_INTEGRATION = createActionType(`${NAME}/RELOGIN_INTEGRATION`);
export const TOGGLE_EXCEEDED_LIMIT_BLOCKED_INTEGRATION = createActionType(`${NAME}/TOGGLE_EXCEEDED_LIMIT_BLOCKED_INTEGRATION`);
export const UPDATE_INTEGRATION = createActionType(`${NAME}/UPDATE_INTEGRATION`);
export const SET_PRIORITY_SYNC = createActionType(`${NAME}/SET_PRIORITY_SYNC`);
export const MIGRATE_TO_AMAZON_SP = createActionType(`${NAME}/MIGRATE_TO_AMAZON_SP`);
export const MIGRATE_TO_NATIVE_OUTLOOK = createActionType(`${NAME}/MIGRATE_TO_NATIVE_OUTLOOK`);
export const GET_INTEGRATION_LOGS = createActionType(`${NAME}/GET_INTEGRATION_LOGS`);

export const GET_ALL_USERS = createActionType(`${NAME}/GET_ALL_USERS`);
export const GET_USER_NOTIFICATIONS = createActionType(`${NAME}/GET_USER_NOTIFICATIONS`);
export const CREATE_NOTIFICATION_FOR_ALL = createActionType(`${NAME}/CREATE_NOTIFICATION_FOR_ALL`);
export const CREATE_NOTIFICATION_FOR_USERS = createActionType(`${NAME}/CREATE_NOTIFICATION_FOR_USERS`);

export const GET_SENT_MESSAGES = createActionType(`${NAME}/GET_SENT_MESSAGES`);

export const DOWNLOAD_TICKETS_LIST_REPORT = createActionType(`${NAME}/DOWNLOAD_TICKETS_LIST_REPORT`);

export const GET_SETUP_SETTINGS = createActionType(`${NAME}/GET_SETUP_SETTINGS`);

export const UPDATE_SETUP_SETTING = createActionType(`${NAME}/UPDATE_SETUP_SETTING`);
export const SETUP_MANUAL_SUBSCRIPTION = createActionType(`${NAME}/SETUP_MANUAL_SUBSCRIPTION`);
export const REMOVE_SUBSCRIPTION = createActionType(`${NAME}/REMOVE_SUBSCRIPTION`);

export const GET_PROMOTER_SCORES = createActionType(`${NAME}/GET_PROMOTER_SCORES`);
export const GET_PROMOTER_SCORE_STATS = createActionType(`${NAME}/GET_PROMOTER_SCORE_STATS`);
export const DOWNLOAD_NPS_REPORT = createActionType(`${NAME}/DOWNLOAD_NPS_REPORT`);
export const GET_MISSED_NPS_COUNT = createActionType(`${NAME}/GET_MISSED_NPS_COUNT`);
export const GET_NPS_USER_STATS = createActionType(`${NAME}/GET_NPS_USER_STATS`);
export const RESET_NEXT_REVIEW_DATE_FOR_USERS = createActionType(`${NAME}/RESET_NEXT_REVIEW_DATE_FOR_USERS`);

export const GET_ADMIN_SETTINGS = createActionType(`${NAME}/GET_ADMIN_SETTINGS`);
export const SAVE_REPEAT_SURVEY = createActionType(`${NAME}/SAVE_REPEAT_SURVEY`);

export const UPDATE_COMPANY_FEATURE = createActionType(`${NAME}/UPDATE_COMPANY_FEATURE`);

export const GET_COMPANY_REMOVED_THREADS = createActionType(`${NAME}/GET_COMPANY_REMOVED_THREADS`);
export const GET_REMOVE_THREAD_LOG = createActionType(`${NAME}/GET_REMOVE_THREAD_LOG`);
export const RESTORE_THREAD = createActionType(`${NAME}/RESTORE_THREAD`);

export const IMPORT_TREADS_FROM_JSON = createActionType(`${NAME}/IMPORT_TREADS_FROM_JSON`);

export const GET_SUBSCRIPTION_PLANS = createActionType(`${NAME}/GET_SUBSCRIPTION_PLANS`);