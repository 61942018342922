import { createActionType } from '../core';
import { NAME } from './constants';

export const CAHNGE_FILTERS_DATE_RANGE = `${NAME}/CHANGE_FILTERS_DATE_RANGE`;
export const CHANGE_REGION_FILTER = `${NAME}/CHANGE_REGION`;

export const GET_OVERVIEW_STATS = createActionType(`${NAME}/GET_OVERVIEW_STATS`);

export const GET_NEW_COMPANIES = createActionType(`${NAME}/GET_NEW_COMPANIES_DATA`);
export const DOWNLOAD_REPORT = createActionType(`${NAME}/DOWNLOAD_REPORT`);
export const GET_NEW_COMPANIES_CHART_DATA = createActionType(`${NAME}/GET_NEW_COMPANIES_CHART_DATA`);

// Usage Report
export const GET_USAGE_REPORT_DATA = createActionType(`${NAME}/GET_USAGE_REPORT_DATA`);

// Subscription cancellation
export const GET_CANCELLATIONS = createActionType(`${NAME}/GET_CANCELLATIONS`);
export const GET_CANCELLATIONS_OVERVIEW = createActionType(`${NAME}/GET_CANCELLATIONS_OVERVIEW`);
export const GET_CANCELLATION_CHART_DATA = createActionType(`${NAME}/GET_CANCELLATION_CHART_DATA`);

// sync jobs
export const GET_SYNC_JOBS_DATA = createActionType(`${NAME}/GET_SYNC_JOBS_DATA`);
export const REMOVE_SYNC_JOBS_DATA = createActionType(`${NAME}/REMOVE_SYNC_JOBS_DATA`);
