/* eslint-disable no-undef */
import React from "react";
import { Menu } from "antd";
import { useLayoutContext } from "../../LayoutContext";
import { colors, css, spacing, StyleSheet } from "../../../styles";
import { LAYOUT } from "../../constants";
import { SidebarItem } from "./SidebarItem";
import { ICONS, ROUTES, useTranslation } from "../../../modules/core";
import { SidebarItemView } from "./SiderbarItemView";

export const SidebarMenu = () => {
    const { t } = useTranslation();
    const { isCollapsedMenu } = useLayoutContext();

    const getItems = () => ([{
        route: ROUTES.dashboardOverview(),
        title: (
            <SidebarItemView
                label={t('screens.dashboard')}
                icon={ICONS.barChart}
            />
        ),
        subItems: [{
            label: t('screens.dashboard'),
            route: ROUTES.dashboardOverview()
        }, {
            label: t('screens.cancellations'),
            route: ROUTES.dashboardCancellations()
        }, {
            label: t('screens.usageReport'),
            route: ROUTES.dashboardUsageReport()
        }, {
            label: t('screens.feedback'),
            route: ROUTES.dashboardNps()
        }]
    }, {
        label: t('screens.companies'),
        route: ROUTES.companies(),
        icon: ICONS.contacts
    }, {
        label: t('screens.sync'),
        route: ROUTES.syncJobs(),
        icon: ICONS.sync
    }, {
        label: t('screens.integrations'),
        route: ROUTES.accounts(),
        icon: ICONS.appstore
    }, {
        label: t('screens.users'),
        route: ROUTES.users(),
        icon: ICONS.user
    }]);

    const renderItem = item => {
        if (item.subItems) {
            return (
                <Menu.SubMenu
                    key={item.route}
                    title={item.title}
                    popupClassName={css(styles.shadowItem, styles.popupSubmenu)}>
                    {item.subItems.map(renderItem)}
                </Menu.SubMenu>
            );
        }

        return (
            <SidebarItem
                key={item.route}
                label={item.label}
                route={item.route}
                icon={item.icon}
            />
        );
    }

    const menuSelectedKeys = () => {
        if (!window || !window.location) return [ROUTES.companies()];
        const url = window.location.pathname;
        return [url];
    };

    return (
        <div className={css(styles.mainContainer)}>
            <Menu
                defaultSelectedKeys={[ROUTES.companies()]}
                selectedKeys={menuSelectedKeys()}
                mode={isCollapsedMenu ? "vertical" : "inline"}
                inlineCollapsed={isCollapsedMenu}
                triggerSubMenuAction="click"
                className={css(styles.container)}
                subMenuCloseDelay={0}>
                {getItems().map(renderItem)}
            </Menu>
        </div>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        ['& .ant-menu-inline-collapsed > .ant-menu-item, ' +
        '.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, ' +
        '.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, ' +
        '.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title']: {
            padding: '0 !important',
            paddingLeft: '0 !important',
            paddingRight: '0 !important'
        }
    },
    container: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        borderRight: 'none',
        background: colors.white,
        width: '100%',
        '& .ant-menu.ant-menu-sub.ant-menu-inline': {
            backgroundColor: colors.sidebar
        },
        '&>.ant-menu-item,>.ant-menu-submenu>.ant-menu-submenu-title': {
            height: LAYOUT.MENU_ITEM_HEIGHT,
            paddingLeft: `${spacing.s1}px !important`,
            paddingRight: `${spacing.s1}px !important`,
            marginTop: '0 !important',
            marginBottom: '0 !important',
            marginLeft: 0,
            marginRight: 0,
            width: `100% !important`
        },
        '& .ant-menu-submenu .ant-menu-item': {
            height: LAYOUT.MENU_SUBITEM_HEIGHT,
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
            marginLeft: `${spacing.s5}px !important`,
            width: `calc(100% - ${spacing.s5}px) !important`,
            marginTop: '0 !important',
            marginBottom: '0 !important',
            borderLeft: `2px solid ${colors.primaryOpacity}`
        },
        '& .ant-menu-submenu .ant-menu-item-selected': {
            borderLeft: `3px solid ${colors.primary}`,
            color: `${colors.black} !important`
        },
        '& .ant-menu-submenu-title:hover': {
            color: `${colors.primary} !important`
        },
        '& .ant-menu-submenu .ant-menu-sub': {
            paddingTop: `${spacing.s1}px !important`,
            paddingBottom: `${spacing.s1}px !important`
        },
        '& .ant-menu-submenu-arrow': {
            display: 'none !important'
        },
        '& .ant-menu-item-selected': {
            backgroundColor: `${colors.transparent} !important`,
            '::after': {
                display: 'none !important'
            }
        },
        '&>.ant-menu-item-selected, .ant-menu-submenu-selected .ant-menu-submenu-title': {
            borderRadius: `${spacing.s5}px !important`,
            backgroundColor: `${colors.menuItemBackground} !important`,
            color: `${colors.primary} !important`,
            '::after': {
                display: 'none !important'
            }
        }
    },
    popupSubmenu: {
        maxHeight: '90vh',
        overflowY: 'auto',
        '& > .ant-menu-vertical': {
            backgroundColor: `${colors.white} !important`
        },
        '& .ant-menu-item-selected': {
            backgroundColor: `${colors.menuItemBackground} !important`
        }
    },
    shadowItem: {
        backgroundColor: colors.white,
        borderRadius: spacing.s0,
        boxShadow: `0 1px 6px 0 ${colors.greyOpacity}`,
    },
});
