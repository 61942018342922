import React from 'react'
import { Layout } from 'antd';
import { colors, css, spacing, StyleSheet } from '../../styles';
import { components as Core, ICONS } from '../../modules/core';
import { useLayoutContext } from '../LayoutContext';
import { LAYOUT } from '../constants';
import { TopbarAdditionalFeatures } from './TopbarAdditionalFeatures';

export const Topbar = () => {
    const { isCollapsedMenu, toggleMenu } = useLayoutContext();

    return (
        <Layout.Header className={css(styles.flexRow, styles.container, isCollapsedMenu ? styles.containerCollapsed : undefined)}>
            <div onClick={toggleMenu} className={css(styles.pointer, styles.drawerButtonContainer)}>
                <Core.Icon
                    color={colors.blackOpacity}
                    size={spacing.s4}
                    type={ICONS.menu}
                />
            </div>
            <div className={css(styles.rightContainer)}>
                <TopbarAdditionalFeatures />
            </div>
        </Layout.Header>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        background: `${colors.white} !important`,
        position: "fixed",
        left: LAYOUT.SIDEBAR_WIDTH,
        right: 0,
        zIndex: 50,
        paddingLeft: 0,
        paddingRight: 0,
        height: LAYOUT.TOPBAR_HEIGHT,
        boxShadow: `4px 0 ${spacing.s1}px 0 ${colors.shadow}`,
        transition: 'all 0.2s, background-color 0s',
        overflow: 'hidden',
        borderBottom: `1px solid ${colors.greyLight}`,
        boxSizing: 'content-box',
    },
    containerCollapsed: {
        left: LAYOUT.SIDEBAR_WIDTH_COLLAPSED
    },
    drawerButtonContainer: {
        borderLeft: `1px solid ${colors.greyLight}`,
        display: 'flex',
        padding: spacing.s3,
        lineHeight: 'normal',
        '@media (max-width: 500px)': {
            paddingLeft: spacing.s2,
            paddingRight: spacing.s2
        },
        '@media (max-width: 360px)': {
            paddingLeft: spacing.s0,
            paddingRight: spacing.s0
        }
    },
    pointer: {
        cursor: 'pointer'
    },
    flexRow: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    rightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        height: LAYOUT.TOPBAR_HEIGHT
    },
});
