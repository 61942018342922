import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Tooltip } from "antd"; // use it because current tooltip doesn't work good with ant menu
import { StyleSheet, css, spacing, fonts, colors } from '../../../styles';
import { components, ICONS, useTranslation } from '../../../modules/core';
import { useLayoutContext } from "../../LayoutContext";
import { LAYOUT } from "../../constants";
import { SidebarBadge } from "./SidebarBadge";

const { Icon, PopConfirm } = components;

export const SidebarItemView = ({
    label,
    icon,
    badge = [],
    isLocked = false,
    hasTooltip = false,
    hasBadgeInCollapsedMode = false,
    route,
    ...props
}) => {
    const { t } = useTranslation();
    const { isCollapsedMenu } = useLayoutContext();
    const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);

    const onShowTooltip = () => setIsVisibleTooltip(true);
    const onHideTooltip = () => setIsVisibleTooltip(false);
    const onUnlock = () => {
        // not implemented
    };

    const itemContent = () => {
        return (
            <div
                onMouseEnter={hasTooltip ? onShowTooltip : null}
                onMouseLeave={hasTooltip ? onHideTooltip : null}
                className={css(styles.container)}>
                <div className={css(styles.titleContainer, isCollapsedMenu ? styles.titleContainerCollapsed : undefined)}>
                    <div className={css(styles.iconContainer)}>
                        <Icon type={icon} color={colors.black} size={spacing.s3} />
                    </div>
                    {!isCollapsedMenu ? (
                        <span className={css(styles.title)}>
                            {label}
                        </span>
                    ) : null}
                </div>
                {isLocked ?
                    <Icon
                        icon={ICONS.lock}
                        color={colors.greyDark}
                        size={isCollapsedMenu ? spacing.s2 : spacing.s3}
                        className={isCollapsedMenu ? styles.collapsedIcon : undefined}
                    /> :
                    <>
                        {(hasBadgeInCollapsedMode || !isCollapsedMenu) ? (
                            <SidebarBadge
                                badge={badge}
                            />
                        ): null}
                    </>
                }
            </div>
        );
    };

    const renderContent = () => {
        if (isLocked) {
            return (
                <PopConfirm
                    onConfirm={onUnlock}
                    title={t("unlockAddonConfirm")}
                    overlayClassName={styles.confirm}>
                    {itemContent()}
                </PopConfirm>
            );
        }

        return itemContent();
    }

    const renderContentWithLink = () => {
        return route && !isLocked ? (
            <Link to={route} className={css(styles.linkContainer)}>
                {renderContent()}
            </Link>
        ) : renderContent();
    };

    return hasTooltip && isCollapsedMenu ? (
        <Tooltip
            visible={isVisibleTooltip}
            title={label}
            placement="right"
            destroyTooltipOnHide={true}>
            {renderContentWithLink()}
        </Tooltip>
    ) : renderContentWithLink();
};

const styles = StyleSheet.create({
    linkContainer: {
        height: '100%',
        ':focus': {
            textDecoration: 'none'
        }
    },
    container: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        height: '100%',
        maxHeight: LAYOUT.MENU_ITEM_HEIGHT,
        overflow: 'hidden',
        alignItems: 'center',
        paddingLeft: spacing.s2,
        paddingRight: spacing.s2,
        flexWrap: 'no-wrap'
    },
    titleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'no-wrap',
        overflowX: 'hidden'
    },
    titleContainerCollapsed: {
        justifyContent: 'center'
    },
    iconContainer: {
        paddingLeft: 2,
        paddingRight: 2
    },
    title: {
        ...fonts.smMedium,
        marginLeft: spacing.s1,
        color: colors.black
    },
    collapsedIcon: {
        position: 'absolute',
        top: 2,
        right: spacing.s1
    },
    confirm: {
        width: spacing.s14*5
    }
});
