import React from "react";
import { lazily } from 'react-lazily';
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { history, constants as coreConstants, components as Core, ROUTES } from '../modules/core';
import { store } from '../store';
import { AppLayout } from "./AppLayout";

const {
    CompaniesPage, AccountsPage, NpsPage, UsersPage, SyncJobsPage,
    CancellationDashboardPage, DashboardOverviewPage, UsageReportPage
} = lazily(() => import('../pages'));

export const Router = () => {
    const handleComponent = component => (routerProps => (<Core.LoadingComponent component={component} routerProps={routerProps} />));

    const getRoutes = () => ([{
        route: ROUTES.companies(),
        component: handleComponent(CompaniesPage)
    }, {
        route: ROUTES.accounts(),
        component: handleComponent(AccountsPage)
    }, {
        route: ROUTES.users(),
        component: handleComponent(UsersPage)
    }, {
        route: ROUTES.dashboardNps(),
        component: handleComponent(NpsPage)
    }, {
        route: ROUTES.syncJobs(),
        component: handleComponent(SyncJobsPage)
    }, {
        route: ROUTES.dashboardOverview(),
        component: handleComponent(DashboardOverviewPage)
    }, {
        route: ROUTES.dashboardCancellations(),
        component: handleComponent(CancellationDashboardPage)
    }, {
        route: ROUTES.dashboardUsageReport(),
        component: handleComponent(UsageReportPage)
    }]);

    const renderRoute = ({ route, component }) => (
        <Route
            key={route}
            exact={true}
            path={route}
            component={component}
        />
    );

    return (
        <ConnectedRouter history={history} store={store}>
            <AppLayout>
                <Switch>
                    {getRoutes().map(renderRoute)}
                    <Redirect to={coreConstants.ROUTES.companies()} />
                </Switch>
            </AppLayout>
        </ConnectedRouter>
    )
};