import moment from "moment";
import { DATE_FORMATS, MARKETPLACES } from "../../core";

const getDate = value => value ? moment(value).format(DATE_FORMATS.DATE) : '';

const getFullTime = value => value ? moment(value).format(DATE_FORMATS.DATE_TIME) : '';

const getFullTimeSeconds = value => value ? moment(value).format(DATE_FORMATS.DATE_TIME_SECONDS) : '';

const getLocalFullTime = value => value ? moment(value).utc(value).local().format(DATE_FORMATS.DATE_TIME) : '';

const getIntegrationSyncTime = (value, inProgress, i18n) => {
    if (value) {
        return moment(value).format(DATE_FORMATS.DATE_TIME);
    }
    if (!inProgress) {
        return i18n.t('integrations.syncIsQueuedNow');
    }
    return '';
};

const getMarketplace = value => MARKETPLACES[value];

export default {
    getDate,
    getFullTime,
    getLocalFullTime,
    getMarketplace,
    getIntegrationSyncTime,
    getFullTimeSeconds
};