import React, { useState, useCallback } from "react";

export const useToggleState = (initialState = false) => {
    const [value, setValue] = useState(!!initialState);

    const onToggle = useCallback(() => setValue(prev => !prev), []);
    const onShow = useCallback(() => setValue(true), []);
    const onHide = useCallback(() => setValue(false), []);

    return [value, onShow, onHide, onToggle];
};
