import React from 'react'
import { useDispatch } from 'react-redux';
import { colors, css, fonts, spacing, StyleSheet } from '../../styles';
import { actions as authActions } from "../../modules/auth";
import { BUTTON_TYPES, components, ICONS, useTranslation } from '../../modules/core';
import { useLayoutContext } from '../LayoutContext';

const { Button } = components;

export const SidebarFooter = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isCollapsedMenu } = useLayoutContext();

    const onLogout = () => dispatch(authActions.signOut());

    return (
        <div className={css(styles.container)}>
            <Button
                type={BUTTON_TYPES.redOutline}
                onPress={onLogout}
                label={!isCollapsedMenu ? t('logout') : null}
                icon={ICONS.logout}
            />
            {!isCollapsedMenu && (
                <p className={css(styles.text)}>{t('footerText')}</p>
            )}
        </div>
    );
};

const styles = StyleSheet.create({
    container: {
        background: `${colors.white} !important`,
        transition: 'background-color 0s',
        borderTop: `1px solid ${colors.greyLight}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1
    },
    text: {
        paddingTop: spacing.s0,
        ...fonts.xxsLight,
        color: colors.textColor
    }
});
