import React from "react";
import { StyleSheet, css, spacing, fonts, colors } from '../../../styles';
import { useLayoutContext } from "../../LayoutContext";

export const SidebarBadge = (({ badge = [] }) => {
    const { isCollapsedMenu } = useLayoutContext();
    const nextValues = () => badge.slice(1).map(value => (<span className={css(styles.value)} key={`value_${Date.now()}`}>{`/${value}`}</span>));
    return (
        Array.isArray(badge) && badge.some(i => !!i) ? (
            <div className={css(styles.container, isCollapsedMenu ? styles.containerCollapsed : undefined)}>
                <span className={css(styles.first)}>
                    {badge[0]}
                </span>
                {nextValues()}
            </div>
        ) : null
    );
});

const styles = StyleSheet.create({
    container: {
        ...fonts.xxsMedium,
        color: colors.primary,
        backgroundColor: colors.white,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        marginLeft: spacing.s0,
        border: `1px solid ${colors.primary}`,
        borderRadius: spacing.s9,
        boxShadow: `0 2px 4px 0 ${colors.primaryOpacity}`,
        lineHeight: `${spacing.s2}px`
    },
    containerCollapsed: {
        position: 'absolute',
        top: spacing.s0,
        right: spacing.s1,
        transform: 'scale(0.8)'
    },
    value: {
        ...fonts.xxsMedium,
        fontSize: spacing.s2
    },
    first: {
        ...fonts.xxsBold,
        lineHeight: `${spacing.s2}px`
    }
});
