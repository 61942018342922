import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BUTTON_TYPES, components as Core, ICONS, omitObjectKey, usePrevious, useTranslation } from '../../../core';
import { valueBuilder } from '../../../companies';
import * as dashboardSelectors from '../../selectors';
import { getSyncJobs, removeSyncJobs } from '../../actions';
import { spacing, StyleSheet, css } from '../../../../styles';

const COLUMNS = {
    syncType: 'syncType',
    companyId: 'companyId',
    accountId: 'accountId',
    startJobTime: 'startJobTime',
    actions: 'actions'
};
export const SyncJobsDashboard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isLoading = useSelector(dashboardSelectors.isLoadingSyncJobs);
    const isRemovingSyncJobs = useSelector(dashboardSelectors.isRemovingSyncJobs);
    const prevIsRemovingSyncJobs = usePrevious(isRemovingSyncJobs);
    const data = useSelector(dashboardSelectors.getSyncJobs);

    useEffect(() => {
        getSyncJobsRequest();
    }, []);

    useEffect(() => {
        if (prevIsRemovingSyncJobs && !isRemovingSyncJobs) {
            getSyncJobsRequest();
        }
    }, [isRemovingSyncJobs]);

    const getSyncJobsRequest = () => dispatch(getSyncJobs());

    const removeSyncJob = syncJob => dispatch(removeSyncJobs(omitObjectKey(syncJob, '_id')));

    const getTableColumns = () => {
        return ([{
            title: t("dashboard.sync.syncType"),
            key: COLUMNS.syncType,
            dataIndex: COLUMNS.syncType
        }, {
            title: t("dashboard.sync.startJobTime"),
            key: COLUMNS.startJobTime,
            dataIndex: COLUMNS.startJobTime,
            render: valueBuilder.getFullTimeSeconds,
        }, {
            title: t("dashboard.sync.companyId"),
            key: COLUMNS.companyId,
            dataIndex: COLUMNS.companyId
        }, {
            title: t("dashboard.sync.accountId"),
            key: COLUMNS.accountId,
            dataIndex: COLUMNS.accountId
        }, {
            title: t("actions"),
            key: COLUMNS.actions,
            render: renderActions
        }]);
    };

    const renderActions = item => (
        <Core.TableAction
            text={t("remove")}
            data={item}
            onPress={removeSyncJob}
            isButton={true}
        />
    );

    const renderCustomFilters = () => (
        <div className={css(styles.top)}>
            <Core.Button
                type={BUTTON_TYPES.primary}
                icon={ICONS.sync}
                label={t('refresh')}
                onPress={getSyncJobsRequest}
                isLoading={isLoading}
            />
        </div>
    );

    return (
        <div>
            <Core.Table
                title={t('screens.sync')}
                columns={getTableColumns()}
                data={data}
                isLoading={isLoading}
                pagination={false}
                hasCustomFilters
                rightContent={renderCustomFilters()}
            />
        </div>
    );
}

const styles = StyleSheet.create({
    top: {
        margin: `${spacing.s1}px 0`,
        display: 'flex',
        alignItems: 'center',
        paddingBottom: spacing.s1
    }
});