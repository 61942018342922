import React from 'react';
import PropTypes from 'prop-types';
import { Button as AntdButton } from 'antd';
import { StyleSheet, css, colors, spacing, fonts } from '../../../../styles';
import { BUTTON_TYPES } from '../../constants';
import Icon from '../Icon';

class Button extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        onPress: PropTypes.func,
        isLoading: PropTypes.bool,
        isDisabled: PropTypes.bool,
        type: PropTypes.string,
        className: PropTypes.object,
        hasFullWidth: PropTypes.bool,
        icon: PropTypes.string
    };

    static defaultProps = {
        onPress: null,
        isLoading: false,
        isDisabled: false,
        type: BUTTON_TYPES.primary,
        className: undefined,
        hasFullWidth: false,
        icon: undefined
    };

    get iconColor() {
        if (this.props.type === BUTTON_TYPES.primary) return colors.white;
        if (this.props.type === BUTTON_TYPES.blueOutline) return colors.primary;
        return colors.red;
    }

    get typeStyle() {
        const { type } = this.props;
        if (type === BUTTON_TYPES.primary) return styles.primaryButton;
        if (type === BUTTON_TYPES.blueOutline) return styles.blueOutlineButton;
        return styles.redOutlineButton;
    }

    renderIcon = (icon, isRight = false) => (
        <span className={css(isRight ? styles.iconRightContainer : styles.iconContainer)}>
            <Icon type={icon} size={spacing.s3} color={this.iconColor} />
        </span>
    );

    render() {
        const { label, onPress, isLoading, isDisabled, type, className, hasFullWidth, icon } = this.props;
        return (
            <AntdButton
                onClick={onPress}
                loading={isLoading}
                disabled={isDisabled}
                type={type}
                className={css(styles.button, hasFullWidth ? styles.fullWidth : undefined, this.typeStyle, className)}>
                {icon && !isLoading ? this.renderIcon(icon) : null}
                {label}
            </AntdButton>
        );
    }
}

export default Button;

const styles = StyleSheet.create({
    button: {
        marginBottom: spacing.s1,
        ...fonts.mdNormal,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        height: 'auto !important',
        borderRadius: spacing.s4,
        border: `1px solid ${colors.primary}`,
        color: colors.white,
        boxShadow: 0,
        backgroundColor: colors.primary,
        ':hover': {
            backgroundColor: colors.primaryDark
        }
    },
    fullWidth: {
        width: '100%'
    },
    primaryButton: {
        color: `${colors.whiteDefault} !important`,
        border: `1px solid ${colors.primary} !important`,
        backgroundColor: `${colors.primary} !important`,
        boxShadow: `0 1px 6px ${colors.primaryOpacity} !important`,
        ':hover': {
            backgroundColor: `${colors.primary} !important`,
            color: `${colors.white} !important`,
            border: `1px solid ${colors.primary} !important`
        },
        ':focus': {
            backgroundColor: `${colors.primary} !important`,
            color: `${colors.white} !important`,
            border: `1px solid ${colors.primary} !important`
        }
    },
    blueOutlineButton: {
        color: `${colors.primary} !important`,
        border: `1px solid ${colors.primary} !important`,
        backgroundColor: `${colors.white} !important`,
        boxShadow: `0 1px 6px ${colors.primaryOpacity} !important`,
        ':hover': {
            backgroundColor: `${colors.primaryBackground} !important`,
            color: `${colors.primary} !important`,
            border: `1px solid ${colors.primary} !important`
        },
        ':focus': {
            backgroundColor: `${colors.primaryBackground} !important`,
            color: `${colors.primary} !important`,
            border: `1px solid ${colors.primary} !important`
        }
    },
    redOutlineButton: {
        color: `${colors.red} !important`,
        border: `1px solid ${colors.red} !important`,
        backgroundColor: `${colors.white} !important`,
        boxShadow: `0 1px 6px ${colors.redOpacity} !important`,
        ':hover': {
            backgroundColor: `${colors.redBackground} !important`,
            color: `${colors.red} !important`,
            border: `1px solid ${colors.red} !important`
        },
        ':focus': {
            backgroundColor: `${colors.redBackground} !important`,
            color: `${colors.red} !important`,
            border: `1px solid ${colors.red} !important`
        }
    },
    iconContainer: {
        marginRight: spacing.s0
    },
    iconRightContainer: {
        marginLeft: spacing.s0
    }
});